$color_purple: #660ec3;
$color_green: #00beca;
$color_gold: #C3966C;

/* FONTS */
.f-10{
    font-size: 10px;
}
.f-13{
    font-size: 13px;
}
.f-14{
    font-size: 14px;
}
.f-15{
    font-size: 13px;
}
.f-16{
    font-size: 16px;
}
.f-17{
    font-size: 17px;
}
.f-18{
    font-size: 18px;
}
.f-19{
    font-size: 19px;
}
.f-20{
    font-size: 20px;
}
.f-22{
    font-size: 22px;
}
.f-24{
    font-size: 24px;
}
.f-25{
    font-size: 25px;
}
.f-26{
    font-size: 26px;
}
.f-28{
    font-size: 28px;
}
.f-30{
    font-size: 30px;
}
.f-32{
    font-size: 32px;
}
.f-33{
    font-size: 33px;
}
.f-35{
    font-size: 35px;
}
.f-39{
    font-size: 39px;
}
.f-40{
    font-size: 40px;
}
.f-42{
    font-size: 40px;
}
/* COLORS */
.cl-green{
    color: $color_green;
}
.cl-purple{
    color: $color_purple;
}
.cl-white{
    color: #fff;
}
.cl-black{
    color: #272727;
}
.cl-gray{
    color: #68646c;
}
.cl-gold{
    color: #C3966C;
}

/* BGS */
.bg-purple{
    background-color: $color_purple;
}
.bg-purple-2{
    background-color: #530075;
}
.bg-green{
    background-color: $color_green;
}
.bg-white{
    background-color: #fff;
}
.bg-gold{
    background-color: #C3966C;
}
/*border*/
.border-white{
    border: 2px solid #fff;
}
.border-purple{
    border: 2px solid $color_purple;
} 
.border-green{
    border: 2px solid $color_green;
} 

/* WHEGHT */
.f-wheight-700{
    font-weight: 700;
}
.f-wheight-600{
    font-weight: 600;
}
.f-wheight-500{
    font-weight: 500;
}
.f-wheight-400{
    font-weight: 400;
}
.f-weight-300{
    font-weight: 300;
}
.f-weight-700{
    font-weight: 700;
}
.f-weight-600{
    font-weight: 600;
}
.f-weight-500{
    font-weight: 500;
}
.f-weight-400{
    font-weight: 400;
}
.f-weight-300{
    font-weight: 300;
}