@import '../../Styles/Mixin.scss';

#form {
    margin-bottom: -45px;
    margin-top: 100px;

    @media screen and (max-width: 365px) {
        margin-top: 80px;
    }

    .box-form {
        background: #F0F0F0;
        box-shadow: -3px 9px 49px 8px rgba(0, 0, 0, 0.22);
        text-align: center;
        padding: 65px 0;

        h4 {
            font-weight: bold;
            font-size: 25px;
            line-height: 172.5%;
            color: #2B2B2B;
            position: relative;

            &::after {
                content: "";
                background: linear-gradient(180deg, #C3966C -13.64%, #FFF3D3 126.56%);
                width: 45px;
                height: 4px;
                position: absolute;
                bottom: -20px;
                left: calc(50% - 22.5px);
            }
        }

        span {
            display: block;
            margin-top: 40px;
        }

        form {
            padding: 0 200px;
            margin-top: 40px;

            span {
                font-weight: bold;
                font-size: 15px;
                color: #C3966C;
                margin-bottom: 20px;
                margin-top: 10px;
            }

            input[type=text],
            input[type=tel],
            input[type=email] {
                width: 100%;
                background: #E4E4E4;
                height: 50px;
                border: none;
                font-size: 15px;
                color: #2A2A2A;
                padding-left: 20px;
                margin-bottom: 23px;

                &::placeholder {
                    color: #2A2A2A;
                }
            }

            .lb-button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 25px;
            }

            .lb-radio {
                padding-top: 10px;
                margin-right: 20px;

                input[type=radio] {
                    margin-right: 5px;
                }

                p.desc {
                    font-size: 15px;
                }
            }

            p.title {
                font-weight: bold;
                font-size: 15px;
                color: #C3966C;
                margin-right: 20px;
            }

            button:disabled,
            button[disabled] {
                border: 1px solid #999999;
                background-color: #cccccc;
                color: #666666;
            }
        }
    }

    @media screen and (max-width: 365px) {

        margin-bottom: 0;

        .content {
            width: 100%;
        }

        .box-form {
            box-shadow: none;
            padding: 65px 3%;

            form {
                padding: 0 0;
                margin-top: 40px;

                span {
                    font-weight: bold;
                    font-size: 15px;
                    color: #C3966C;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }

                input[type=text] {
                    width: 100%;
                    background: #E4E4E4;
                    height: 50px;
                    border: none;
                    font-size: 15px;
                    color: #2A2A2A;
                    padding-left: 20px;
                    margin-bottom: 23px;

                    &::placeholder {
                        color: #2A2A2A;
                        font-weight: 600;
                    }
                }

                .lb-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 25px;

                    button {
                        border: none;
                    }
                }

                .ajuste-mobal {
                    display: flex !important;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start !important;
                }

                .lb-radio {
                    padding-top: 10px;
                    margin-right: 20px;

                    input[type=radio] {
                        margin-right: 5px;
                    }

                    p.desc {
                        font-size: 15px;
                        display: block !important;
                    }
                }

                p.title {
                    font-weight: bold;
                    font-size: 15px;
                    color: #C3966C;
                    margin-right: 20px;
                }

            }
        }
    }
}