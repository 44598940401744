@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,700&display=swap');
@import './Mixin.scss';
@import './hamburguer-menu.scss';

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

p {
    margin: 0;
}

a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    overflow-x: hidden;
}

input,
button,
textarea {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.desk-none {
    display: none !important;
}

.mb-none {
    display: block;
}

.clear {
    clear: both;
}

.content {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 1350px;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 1405px) {
        padding: 0 15px;
    }
    @media screen and (max-width: 400px) {
        padding: 0 0px;
    }
}

.bt {
    height: 46px;
    line-height: 0;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $color_gold;
    border: 2px solid $color_gold;
    color: #fff;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;

    .icon {
        display: inline-block;
        vertical-align: -2px;

    }

    &:hover {
        background-color: #fff;
        color: $color_gold;
        transition: 0.5s;
    }

    @media screen and (max-width: 365px) {
        width: 100%;
        height: 50px;
    }
}


.bt:hover {
    text-decoration: none;
}

.clear {
    clear: both;
}

/* HEADER */
#header {
    padding: 45px 0;
    position: absolute;
    width: 100%;

    ul.menu {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        li {
            display: inline-block;
            margin-right: 45px;

            &:last-child {
                margin-right: 0;
            }

            a {
                color: #fff;
                font-weight: 700;
                font-size: 16px;

                &:hover {
                    color: $color_gold;
                    text-decoration: none;
                    transition: 0.5s;
                }
            }
        }
    }

    .social-link {
        color: #F2DEBC;
        border: #F2DEBC 1px solid;
        border-radius: 34px;
        height: 34px;
        width: 34px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: #fff;
            border: #fff 1px solid;
            transition: 0.5s;
        }
    }

}

.line-menu {
    height: 197px;
    background: #272727;

    @media screen and (max-width: 365px) {
        height: 105px;
    }
}

#footer {
    #footer1 {
        background: #282828;
        padding-top: 125px;
        padding-bottom: 45px;
        color: #fff;

        .logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            div {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 39px;
                    height: 39px;
                    color: #F2DEBC;
                    border: 1px solid #F2DEBC;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.5s;

                    &:hover {
                        color: #fff;
                        border: 1px solid #fff;
                        transition: 0.5s;
                    }
                }

            }
        }

        .menus {
            position: relative;

            a {
                font-weight: bold;
                font-size: 14px;
                line-height: 151.5%;
                display: block;
                margin-bottom: 14px;
                color: #fff;
                cursor: pointer;
                transition: 0.5s;

                &.menu-pp {
                    font-weight: 500;
                }

                &:hover {
                    color: #C3966C;
                    text-decoration: none;
                    transition: 0.5s;
                }
            }

            &::after {
                content: "";
                height: 210px;
                width: 1px;
                background: #161616;
                position: absolute;
                left: -15px;
                top: -7px;
            }
        }

        .ends {
            font-size: 14px;
            font-weight: 400;
            line-height: 172.5%;

            span {
                font-weight: 700;
            }
        }

        .infos {
            font-size: 12px;
            display: block;
            margin-top: 20px;
        }
    }

    #footer2 {
        background: #1F1F1F;
        color: #fff;
        height: 55px;
        font-weight: 300;
        font-size: 14px;

        strong {
            font-weight: 700;
        }

        a {
            color: #fff;
            font-weight: 700;
        }

        .alft {
            display: flex;
            align-items: center;
            height: 55px;
        }
    }
}

.whatappfixed {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 2;

    img {
        animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        width: 50px;
    }
}