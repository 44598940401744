@import '../../Styles/Mixin.scss';

#sobre {
    #banner {
        background-image: url(../../images/sobre/banner.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 718px;
        border-bottom: 6px solid $color_gold;
        padding-top: 237px;
    }

    @media screen and (max-width: 365px) {
        #banner {
            height: 907px;
            padding: 130px 10px 0 10px;

            .bt {
                margin-top: 50px;
            }
        }
    }

    #sobre {
        margin-top: 50px;

        h3 {
            margin-bottom: 50px;
        }

        .box-sobre {
            height: 493px;
            width: 100%;
            padding: 45px 28px;
            box-shadow: -3px 9px 49px 8px rgba(0, 0, 0, 0.22);
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 6px;
                position: absolute;
                bottom: -6px;
                left: 0;
                background: linear-gradient(90deg, #C3966C 0%, #FFF3D3 100%);
            }

            span.title {
                font-weight: bold;
                font-size: 30px;
                margin-bottom: 25px;
                display: block;
                padding-left: 85px;
            }

            &.bs1 {
                span::after {
                    content: "";
                    width: 49.94px;
                    height: 43.81px;
                    position: absolute;
                    left: 40px;
                    top: 35px;
                    background: url(../../images/sobre/credibilidade.png)
                }
            }

            &.bs2 {
                span {
                    padding-left: 60px
                }

                span::after {
                    content: "";
                    width: 36px;
                    height: 45px;
                    position: absolute;
                    left: 30px;
                    top: 40px;
                    background: url(../../images/sobre/seguranca.png)
                }
            }

            &.bs3 {
                span {
                    padding-left: 50px
                }

                span::after {
                    content: "";
                    width: 27px;
                    height: 50px;
                    position: absolute;
                    left: 30px;
                    top: 35px;
                    background: url(../../images/sobre/facilidade.png)
                }
            }

            p {
                font-size: 16px;
                line-height: 172.5%;

            }

            p.bx1 {
                position: relative;
                margin-bottom: 20px;
                padding-left: 10px;

                span {
                    position: absolute;
                    left: 0px;
                    top: 12px;
                    width: 3px;
                    height: 3px;
                    background: #000;
                    border-radius: 200px;
                }
            }
        }

        .bg-gray {
            background: #F0F0F0;

        }

        .bg-gold {
            background: #F2DEBC;
            margin-top: 100px;
        }

        .aviso {
            font-size: 15px;
            display: block;
            margin: 70px auto 0;
        }

        .bt {
            margin-top: 100px;
            width: 500px;
            margin-bottom: 100px;
        }
    }

    @media screen and (max-width: 365px) {
        #sobre {
            margin-top: 80px;

            .box-sobre {
                height: auto;
                width: 100%;
                padding: 45px 29px;
                box-shadow: -3px 9px 49px 8px rgba(0, 0, 0, 0.22);
                position: relative;

                &::after {
                    content: "";
                    width: 100%;
                    height: 6px;
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    background: linear-gradient(90deg, #C3966C 0%, #FFF3D3 100%);
                }

                span.title {
                    font-weight: bold;
                    font-size: 30px;
                    margin-bottom: 25px;
                    display: block;
                    position: relative;
                }

                p {
                    font-size: 16px;
                    line-height: 172.5%;

                }

                p.bx1 {
                    position: relative;
                    margin-bottom: 20px;
                    padding-left: 10px;

                    span {
                        position: absolute;
                        left: 0px;
                        top: 12px;
                        width: 3px;
                        height: 3px;
                        background: #000;
                        border-radius: 200px;
                    }
                }
            }

            .bg-gold {
                background: #F2DEBC;
                margin-top: 0px !important;
                margin-bottom: 50px;
            }

        }

        .bs1 span::after {
            left: 12px !important;
            top: -8px !important;
        }

        .bs2 span::after {
            left: 12px !important;
            top: -8px !important;
        }

        .bs3 span::after {
            left: 4px !important;
            top: -8px !important;
        }

        .bt {
            height: 76px;
            line-height: 22px;
            margin-bottom: 48px !important;
            margin-top: 71px !important;
        }

    }
}