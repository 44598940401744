@import '../../Styles/Mixin.scss';

#vendadeprecatorios {
    #banner {
        background-image: url(../../images/index/banner.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 917px;
        padding-top: 287px;
        border-bottom: 6px solid $color_gold;

        h1 {
            text-transform: uppercase;
        }

        h2 {
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 365px) {
        #banner {
            height: 820px;
            padding: 120px 10px 0 10px;
            .bt {
                margin-top: 50px;
            }
        }
    }

    #passos {
        padding: 56px 0 89px;

        h2 {
            font-weight: bold;
            line-height: 159.5%;
            color: #C3966C;
            margin: 0;

        }

        .align-right {
            display: flex;
            justify-content: flex-end;
        }

        .box-passos {
            background: #F0F0F0;
            height: 164px;
            position: relative;
            width: 634px;
            margin-top: 50px;

            &::after {
                content: "";
                width: 8px;
                height: 164px;
                background: linear-gradient(180deg, #C3966C -13.64%, #FFF3D3 126.56%);
                position: absolute;
                left: 0;
                top: 0;
            }

            &.hth2 {
                height: 196px;

                &::after {
                    height: 196px;
                }
            }

            .box-image {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                span {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 172.5%;
                }

                p {
                    font-size: 15px;
                    line-height: 172.5%;
                }
            }
        }

        .button-line {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 70px;
        }

        @media screen and (max-width: 365px) {
            padding: 0px 0 65px;

            h2 {
                display: none;
            }

            .align-right {
                display: flex;
                justify-content: flex-end;
            }

            .box-passos {
                background: #F0F0F0;
                height: auto;
                position: relative;
                width: 100%;
                margin-top: 30px;
                padding: 30px 15px 25px 15px;

                &::after {
                    content: "";
                    width: 100%;
                    height: 8px;
                    background: linear-gradient(90deg, #C3966C 0%, #FFF3D3 100%);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    top: inherit;
                }

                &.hth2 {
                    height: auto;

                    &::after {
                        height: 8px;
                    }
                }

                .text {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    span {
                        padding: 0 0 25px 85px;
                    }

                    span.image1 {
                        &::after {
                            content: "";
                            background: url(../../images/passos/analise.png);
                            position: absolute;
                            width: 74px;
                            height: 63px;
                            left: 0;
                            top: -13px;
                        }
                    }

                    span.image2 {
                        &::after {
                            content: "";
                            background: url(../../images/passos/proposta.png);
                            position: absolute;
                            width: 54px;
                            height: 69px;
                            left: 13px;
                            top: -17px;
                        }
                    }

                    span.image3 {
                        &::after {
                            content: "";
                            background: url(../../images/passos/contrato.png);
                            position: absolute;
                            width: 58px;
                            height: 64px;
                            left: 10px;
                            top: -15px;
                        }
                    }

                    span.image4 {
                        &::after {
                            content: "";
                            background: url(../../images/passos/conta.png);
                            position: absolute;
                            width: 73px;
                            height: 72px;
                            left: -3px;
                            top: -21px;
                        }
                    }
                }
            }

            .button-line {
                margin-top: 40px;

                a {
                    width: 90% !important;
                }

            }
        }
    }

}

#depoimentos {
    .box-depoimento {
        background: #EADBC0;
        min-height: 450px;
        position: relative;

        &::after {
            content: "";
            background: url(../../images/depoimentos/image.png);
            width: 975px;
            height: 450px;
            position: absolute;
            z-index: 1;
        }

        &::before {
            content: "";
            background: url(../../images/depoimentos/circle.svg);
            width: 441px;
            height: 441px;
            position: absolute;
            z-index: 2;
            top: 59px;
            left: 59px;
        }

        h3 {
            font-size: 25px;
            line-height: 172.5%;
            font-weight: bold;
            margin-bottom: 40px;
            margin-top: 80px;
        }

        .depo {
            z-index: 2;
            position: relative;
            display: none;
            opacity: 0;
            transition: 0.5s;

            p {
                font-size: 18px;
                line-height: 172.5%;
            }

            span {
                font-size: 16px;
                margin-top: 40px;
                display: block;
            }

            &.active {
                display: block;
                opacity: 1;
                transition: 0.5s;
            }
        }

        .buttons {
            position: absolute;
            right: 20px;
            bottom: 20px;

            a {
                width: 15px;
                height: 15px;
                background: #DBC4A6;
                margin-right: 15px;

                &.active {
                    border: 1px solid #000;
                }
            }
        }
    }

    @media screen and (max-width: 365px) {
        .box-depoimento {
            position: relative;
            padding: 280px 10px 100px 10px;

            &::after {
                top: 0;
                left: -4px;
                width: 365px;
                background: url(../../images/depoimentos/image-mobile.png);
                background-repeat: no-repeat;
            }

            &::before {
                display: none;
            }

            .buttons {
                position: absolute;
                right: 110px;
                bottom: -60px;

                a {
                    width: 20px;
                    height: 20px;
                    background: #DBC4A6;
                    margin-right: 15px;

                    &.active {
                        border: 1px solid #000;
                    }
                }
            }
        }
    }
}

// #form {
//     margin-bottom: -45px;
//     margin-top: 10px;

//     .box-form {
//         background: #F0F0F0;
//         box-shadow: -3px 9px 49px 8px rgba(0, 0, 0, 0.22);
//         text-align: center;
//         padding: 65px 0;

//         h4 {
//             font-weight: bold;
//             font-size: 25px;
//             line-height: 172.5%;
//             color: #2B2B2B;
//             position: relative;

//             &::after {
//                 content: "";
//                 background: linear-gradient(180deg, #C3966C -13.64%, #FFF3D3 126.56%);
//                 width: 45px;
//                 height: 4px;
//                 position: absolute;
//                 bottom: -20px;
//                 left: calc(50% - 22.5px);
//             }
//         }

//         span {
//             display: block;
//             margin-top: 40px;
//         }

//         form {
//             padding: 0 200px;
//             margin-top: 40px;

//             span {
//                 font-weight: bold;
//                 font-size: 15px;
//                 color: #C3966C;
//                 margin-bottom: 20px;
//                 margin-top: 10px;
//             }

//             input[type=text],
//             input[type=tel],
//             input[type=email] {
//                 width: 100%;
//                 background: #E4E4E4;
//                 height: 50px;
//                 border: none;
//                 font-size: 15px;
//                 color: #2A2A2A;
//                 padding-left: 20px;
//                 margin-bottom: 23px;

//                 &::placeholder {
//                     color: #2A2A2A;
//                 }
//             }

//             .lb-button {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 margin-top: 25px;
//             }

//             .lb-radio {
//                 padding-top: 10px;
//                 margin-right: 20px;

//                 input[type=radio] {
//                     margin-right: 5px;
//                 }

//                 p.desc {
//                     font-size: 15px;
//                 }
//             }

//             p.title {
//                 font-weight: bold;
//                 font-size: 15px;
//                 color: #C3966C;
//                 margin-right: 20px;
//             }

//         }
//     }

//     @media screen and (max-width: 365px) {

//         margin-bottom: 0;

//         .content {
//             width: 100%;
//         }

//         .box-form {
//             box-shadow: none;
//             padding: 65px 3%;

//             form {
//                 padding: 0 0;
//                 margin-top: 40px;

//                 span {
//                     font-weight: bold;
//                     font-size: 15px;
//                     color: #C3966C;
//                     margin-bottom: 20px;
//                     margin-top: 10px;
//                 }

//                 input[type=text] {
//                     width: 100%;
//                     background: #E4E4E4;
//                     height: 50px;
//                     border: none;
//                     font-size: 15px;
//                     color: #2A2A2A;
//                     padding-left: 20px;
//                     margin-bottom: 23px;

//                     &::placeholder {
//                         color: #2A2A2A;
//                         font-weight: 600;
//                     }
//                 }

//                 .lb-button {
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     margin-top: 25px;

//                     button {
//                         border: none;
//                     }
//                 }

//                 .ajuste-mobal {
//                     display: flex !important;
//                     flex-direction: column;
//                     justify-content: flex-start;
//                     align-items: flex-start !important;
//                 }

//                 .lb-radio {
//                     padding-top: 10px;
//                     margin-right: 20px;

//                     input[type=radio] {
//                         margin-right: 5px;
//                     }

//                     p.desc {
//                         font-size: 15px;
//                         display: block !important;
//                     }
//                 }

//                 p.title {
//                     font-weight: bold;
//                     font-size: 15px;
//                     color: #C3966C;
//                     margin-right: 20px;
//                 }

//             }
//         }
//     }
// }